import React, { useState, useEffect, useCallback } from 'react';
import { Popup, Position } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { Formio } from 'react-formio';
import { authenticateCronofy } from '../../services/cronofyService';
import { AppConfig } from '../../config';
import { useAuth0 } from '@auth0/auth0-react';
import './Calendar.scss';

declare global {
   interface Window {
      CronofyElements: any;
   }
}

export default function Calendar() {
   const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

   const history = useHistory();
   const [isShowConfirmPopup, setShowConfirmPopup] = useState(false);

   const onLoad = useCallback(async () => {
      // Retrieve Form.io token from Id Token
      const idTokenClaims = await getIdTokenClaims({
         audience: AppConfig.apiUrl,
      });

      const formIoToken = idTokenClaims['https://login.eldertree.io/form-io-token'];
      Formio.setToken(formIoToken);

      const counselorId = idTokenClaims['https://login.eldertree.io/form-io-counselor-id'];
      window.localStorage.setItem('user_id', counselorId);

      const accessToken = await getAccessTokenSilently({
         audience: AppConfig.apiUrl,
      });

      let cronofySub, elementToken;
      try {
         const cronofyAuthResponse = await authenticateCronofy(accessToken);
         cronofySub = cronofyAuthResponse.sub;
         elementToken = cronofyAuthResponse.elementToken;
      } catch (e) {
         console.log('Cronofy authorization failed', e);
      }

      if (!cronofySub || !elementToken) {
         setShowConfirmPopup(true);
      }

      if (window.CronofyElements && elementToken) {
         window.CronofyElements.Agenda({
            element_token: elementToken,
            target_id: 'calendar-viewer',
            styles: {
               colors: {
                  calendars: ['#FFCCC5', '#a28089'],
               },
            },
         });
      }
   }, []);

   useEffect(() => {
      onLoad();
   }, [onLoad]);

   return (
      <div className="calendar-page">
         <h1>Calendar</h1>
         <div className="card responsive-padings">
            <div id="calendar-viewer"></div>
         </div>
         <Popup
            className="popup-creation-confirm"
            visible={isShowConfirmPopup}
            onHiding={() => {
               setShowConfirmPopup(false);
            }}
            closeOnOutsideClick={false}
            shading={isMobile}
            width={isMobile ? 'calc(100% - 40px)' : '80%'}
            height="auto"
            title="Notice"
            deferRendering={false}
         >
            <Position of={isMobile ? '#content' : '#container'} />
            <p>Before a student can schedule an appointment with you, you’ll need to connect your calendar service.</p>
            <div className="d-flex flex-row-reverse">
               <Button
                  text="Go to Settings: Calendar"
                  onClick={() => {
                     history.push('/settings/calendar');
                  }}
                  className="hide-button primary-button"
               />
            </div>
         </Popup>
      </div>
   );
}
