import React, { useEffect, useCallback } from 'react';
import { authenticateCronofy } from '../../services/cronofyService';
import './Home.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { AppConfig } from '../../config';

declare global {
   interface Window {
      CronofyElements: any;
   }
}

const Home: React.FC = () => {
   const { getAccessTokenSilently } = useAuth0();

   const onLoad = useCallback(async () => {
      const accessToken = await getAccessTokenSilently({
         audience: AppConfig.apiUrl,
      });
      const { elementToken } = await authenticateCronofy(accessToken);

      if (window.CronofyElements && elementToken) {
         window.CronofyElements.Agenda({
            element_token: elementToken,
            target_id: 'cronofy-agenda',
            styles: {
               colors: {
                  calendars: ['#FFCCC5', '#a28089'],
               },
            },
         });
      }
   }, []);

   useEffect(() => {
      onLoad();
   });

   return (
      <div className="home-page">
         <h1>Upcoming Appointments (Agenda Viewer)</h1>

         <div id="cronofy-agenda" style={{ height: 'calc(100vh - 240px)' }}></div>
      </div>
   );
};

export default Home;
