import React, { useState, useEffect } from 'react';
import dateFormat from 'dateformat';
import './CurrentTime.scss';

export default function CurrentTime() {
   const [time, setTime] = useState(new Date());

   useEffect(() => {
      const timer = setTimeout(() => {
         setTime(new Date());
      }, 1000 * 60);
      return () => clearTimeout(timer);
   }, [time]);

   return (
      <div className="current-timer">
         <p className="m-0 p-0">
            <strong>{dateFormat(time, 'h:MM TT')}</strong>
            {dateFormat(time, ', dddd, mmmm dS, yyyy (Z)')}
         </p>
      </div>
   );
}
