import React, { useRef } from 'react';
import { Button } from 'devextreme-react/button';
import { AppConfig } from '../../config';
import './LinkCard.scss';

interface LinkCardProps {
   _id: string;
   id: string;
   title: string;
   description: string;
   isRequiredID: string;
   hasQuestionnaire: string;
   onDelete: Function;
}

const LinkCard: React.FC<LinkCardProps> = props => {
   const { _id, title, description, isRequiredID, onDelete, hasQuestionnaire } = props;
   const textAreaRef = useRef<HTMLTextAreaElement>(null);

   let url = AppConfig.apiUrl.slice(0, -4) + '/#/get-started/' + _id;

   function copyToClipboard(e: any) {
      if (textAreaRef !== null) {
         textAreaRef?.current?.select();
         document.execCommand('copy');
      }
   }

   return (
      <div className="Link-card-container col-sm-12 col-md-12 col-lg-6 col-xl-4">
         <div className="card link-card-content">
            <div className="d-flex justify-content-between">
               <h2>{title}</h2>
               <Button className="bt-delete" onClick={() => onDelete()}>
                  <i className="dx-icon-trash" />
               </Button>
            </div>
            <p>{description}</p>
            <div className="link-container">
               <textarea ref={textAreaRef}>{url}</textarea>
               <Button elementAttr={{ 'data-testid': 'copy-button' }} onClick={copyToClipboard}>
                  Copy
               </Button>
            </div>
            <p className="mb-0">Student ID Number Required: {isRequiredID === 'yes' ? 'ON' : 'OFF'} </p>
            <p>Questionnaire: {hasQuestionnaire === 'Yes' ? 'ON' : 'OFF'} </p>
         </div>
      </div>
   );
};

export default LinkCard;
