import React, { useState } from 'react';
import { Button } from 'devextreme-react/button';

import LeftBgImg from '../../assets/images/auth_left.png';
import RightBgImg from '../../assets/images/auth_right.png';
import './AppointmentCancelConfirm.scss';

export default function AppointmentCancelConfirm() {
   const [isConfirmed, setConfirmed] = useState(false);

   const handleConfirm = () => {
      setConfirmed(true);
   };

   return (
      <div className="container" style={{ height: '100vh', textAlign: 'center' }}>
         <img alt="left-bg" style={{ zIndex: -1 }} src={LeftBgImg} className="left-bg" />
         <img alt="right-bg" style={{ zIndex: -1 }} src={RightBgImg} className="right-bg" />

         <div id="confirm-area" className={!isConfirmed ? 'show' : 'hide'}>
            <h2>Cancel Appointment!</h2>
            <p>Your appointment:</p>
            <h2 className="date-header" data-testid="date">
               "Conseling Meeting" <br />
               Alex Song <br /> Monday, September 14, 2020 9:00AM - 9:30AM
            </h2>
            <p style={{ paddingTop: 20 }}>Are you sure want to cancel this appointment?</p>
            <p className="comment-header">Reason for cancellation (optional):</p>
            <textarea className="comment" />
            <Button className="button btn-primary" style={{ marginTop: 20 }} onClick={handleConfirm}>
               Yes, cancel this appointment
            </Button>
         </div>

         <div id="confirm-area" className={isConfirmed ? 'show' : 'hide'}>
            <h2>You've successfully cancelled your appointment:</h2>
            <h2 className="date-header" data-testid="date">
               "Conseling Meeting" <br />
               Alex Song
            </h2>
            <p>A confirmation was sent to your email</p>
         </div>
      </div>
   );
}
