import React from 'react';
import { connect } from 'react-redux';
import { selectRoot, logout } from 'react-formio';
import DropDownButton from 'devextreme-react/drop-down-button';
import './AccountOption.scss';
import { useAuth0 } from '@auth0/auth0-react';

interface AccountOptionProps {
   style: Object;
   subscriptionStatus: string;
   auth: any;
   logout: any;
}

// TODO: this is not being used
const AccountOption: React.FC<AccountOptionProps> = props => {
   const { style } = props;

   const { user, logout } = useAuth0();
   const firstName = user.given_name || user.nickname;
   const nameCapitalized = firstName.charAt(0).toUpperCase() + firstName.slice(1)

   const items = [{ id: 1, name: 'Log Out' }];

   const onItemClick = (e: any) => {
      if (e.itemData.name === 'Log Out') {
         window.localStorage.clear();

         logout({ returnTo: window.location.origin });
      }
   };

   return (
      <div className="account-option" style={style}>
         <DropDownButton
            text={nameCapitalized}
            dropDownOptions={{ width: 180 }}
            onItemClick={onItemClick}
            items={items}
            displayExpr="name"
            keyExpr="id"
            deferRendering={false}
            elementAttr={{ 'data-testid': 'account-option-trigger-button' }}
         />
      </div>
   );
};

const mapStateToProps = (state: any) => {
   return {
      auth: selectRoot('auth', state),
   };
};

const mapDispatchToProps = (dispatch: any) => {
   return {
      logout: () => {
         dispatch(logout());
      },
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountOption);
