import React from 'react';
import { Popup, Position } from 'devextreme-react/popup';
import { isMobile } from 'react-device-detect';
import { Form } from 'react-formio';
import './UploadFileModal.scss';

interface UploadFileModalProps {
   visible: boolean;
   hideModal: any;
   elementAttr: any;
   onSubmitDone: (_submission: any) => void;
}

const UploadFileModal: React.FC<UploadFileModalProps> = props => {
   const { visible, hideModal, elementAttr, onSubmitDone } = props;
   const formioBaseURL = window._ENV.FormIOProjectURL;

   return (
      <Popup
         className="popup-file-upload"
         visible={visible}
         onHiding={hideModal}
         closeOnOutsideClick={true}
         shading={isMobile ? true : false}
         width={isMobile ? 'calc(100% - 40px)' : '80%'}
         height="auto"
         title="Upload a Document"
         elementAttr={elementAttr}
         deferRendering={false}
      >
         <Position of={isMobile ? '#content' : '#document-section'} />
         <div className="form-container">
            {visible && (
               <Form src={`${formioBaseURL}documents`} onSubmitDone={onSubmitDone} onCustomEvent={hideModal} />
            )}
         </div>
      </Popup>
   );
};

export default UploadFileModal;
