import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { getCronofyElementToken, getCronofySubById } from '../../services/cronofyService';
import { getSlots, saveEvent } from '../../services/availabilityService';
import SpinnerIcon from '../../assets/icons/icon-spinner.gif';

import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import LeftBgImg from '../../assets/images/auth_left.png';
import RightBgImg from '../../assets/images/auth_right.png';
import './AppointmentPicker.scss';
import { getLinkDetail } from '../../services/linkService';

export default function AppointmentPicker() {
   const history = useHistory();
   const [isLoading, setLoading] = useState(true);
   const [slotDate, setSlotDate] = useState('');
   const [slotTime, setSlotTime] = useState('');
   const [isShowConfirm, setShowConfirm] = useState(false);
   const [isShowVerified, setShowVerified] = useState(false);
   const [slots, setSlots] = useState([]);
   const [start, setStart] = useState(new Date());
   const [end, setEnd] = useState(new Date());
   const [comment, setComment] = useState('');

   const { linkId } = useParams<Record<string, string | undefined>>();

   const studentFirstName = window.localStorage.getItem('student_first_name');

   const goToForm = () => {
      history.push('/student-form' + history.location.search);
   };

   const onConfirm = async () => {
      const calendarId = window.localStorage.getItem('calendar_id');
      if (!calendarId) {
         console.warn('calendar_id left unset');
      }
      const studentId = window.localStorage.getItem('student_id');
      if (!studentId) {
         console.warn('student_id left unset');
      }

      const linkDetailResponse = await getLinkDetail(linkId);
      const counselorId = linkDetailResponse.data.counselor.id;

      await saveEvent(counselorId, start, end, calendarId, linkId, studentId, comment);
      setShowConfirm(false);
      setShowVerified(true);
   };

   const onLoad = useCallback(async () => {
      let elementToken;

      if (!window.CronofyElements) {
         return;
      }

      const linkDetailResponse = await getLinkDetail(linkId);
      const counselorId = linkDetailResponse.data.counselor.id;

      const account = await getCronofySubById(counselorId);

      if (account.data) {
         const calendar_id = account.data.calendar.profile_calendars[0].calendar_id;
         const tokenResponse = await getCronofyElementToken(account.data.sub);

         window.localStorage.setItem('calendar_id', calendar_id);

         if (tokenResponse.data.element_token) {
            elementToken = tokenResponse.data.element_token.token;
            setLoading(false);
         }
      }

      elementToken &&
         window.CronofyElements.SlotPicker({
            element_token: elementToken,
            target_id: 'cronofy-slot-picker',
            availability_query: {
               participants: [
                  {
                     required: 'all',
                     members: [{ sub: account.data.sub }],
                  },
               ],
               required_duration: { minutes: 60 },
               query_periods: slots,
            },
            config: {
               mode: 'no_confirm',
            },
            styles: {
               prefix: 'custom-name',
            },
            tzid: 'America/Los_Angeles',
            callback: (notification: any) => confirmCallback(notification),
         });
   }, [slots]);

   const confirmCallback = (data: any) => {
      if (data.notification.type === 'slot_selected') {
         setShowConfirm(true);
         const dateStart = new Date(data.notification.slot.start);
         const dateEnd = new Date(data.notification.slot.end);

         setStart(dateStart);
         setEnd(dateEnd);
         setSlotDate(moment(dateStart).format('dddd, MMMM Do'));
         setSlotTime(moment(dateStart).format('LT') + ' - ' + moment(dateEnd).format('LT'));
      }
   };

   useEffect(() => {
      if (slots.length > 0) {
         onLoad();
      } else {
         // TODO: show a "No slots available" message to the user
      }
   }, [slots, onLoad]);

   useEffect(() => {
      (async () => {
         const linkDetailResponse = await getLinkDetail(linkId);
         const counselorId = linkDetailResponse.data.counselor.id;

         const cronofySubResponse = await getCronofySubById(counselorId);
         const currentDate = moment();
         const weekStart = currentDate.add(1, 'days').startOf('day').toISOString();
         const weekEnd = currentDate.add(8, 'days').startOf('day').toISOString();

         if (!cronofySubResponse || !cronofySubResponse.data) {
            return;
         }

         let data = [];
         const getSlotsResponse = await getSlots(cronofySubResponse.data.sub, counselorId, weekStart, weekEnd);
         if (getSlotsResponse && getSlotsResponse.data && getSlotsResponse.data.available_slots) {
            data = getSlotsResponse.data.available_slots.map((item: any) => {
               return {
                  start: item.start,
                  end: item.end,
               };
            });
         }

         setSlots(data);
      })();
   }, []);

   return (
      <div className="container" style={{ height: '100vh', textAlign: 'center' }}>
         <img alt="left-bg" style={{ zIndex: -1 }} src={LeftBgImg} className="left-bg" />
         <img alt="right-bg" style={{ zIndex: -1 }} src={RightBgImg} className="right-bg" />
         <div id="slot-confirm" className={isShowConfirm ? 'show' : 'hide'}>
            <h2 className="slot-date-header">Date Selected: {slotDate}</h2>
            <p className="slot-date">{slotTime}</p>
            <p className="slot-comment-header">
               Is there anything you want to say before we meet up? Let me know! (optional)
            </p>
            <textarea
               className="slot-comment"
               onChange={event => {
                  setComment(event.target.value);
               }}
            />
            <div className="d-flex button-container">
               <Button className="button secondary-button" onClick={() => setShowConfirm(false)}>
                  Back
               </Button>
               <Button className="button primary-button" onClick={() => onConfirm()}>
                  Confirm
               </Button>
            </div>
         </div>
         <div id="confirm-verified" className={isShowVerified ? 'show' : 'hide'}>
            <h2>SUPER!</h2>
            <p>Your appointment is set for:</p>
            <h2 data-testid="date" className="slot-date-header">
               {slotDate}
            </h2>
            <h2 data-testid="time" className="slot-date-header">
               {slotTime}
            </h2>
            <p data-testid="greetings" style={{ paddingTop: 50 }}>
               Thanks, {studentFirstName}! <br />
               A confirmation was sent to your email.
               <br />
               See you soon!
            </p>
         </div>
         <div className="slot-container">
            <div id="cronofy-slot-picker" className={isShowConfirm || isShowVerified ? 'hide' : 'show'}></div>
            {isLoading ? (
               <img alt="spinner" width="50px" src={SpinnerIcon} className="spinner" data-testid="spinner" />
            ) : (
               <div>
                  {!isShowConfirm && !isShowVerified && (
                     <Button className="back-button secondary-button" onClick={goToForm}>
                        Back
                     </Button>
                  )}
               </div>
            )}
         </div>
      </div>
   );
}
