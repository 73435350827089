import { callApi } from '../hooks/use-api';

export const getCronofyProvider = async (accessToken: string) => {
   return await callApi('/api/providers', accessToken);
};

export const getCronofySub = async (accessToken: string) => {
   return await callApi('/api/auth/cronofy/account', accessToken);
};

export const getCronofySubById = async (id: any) => {
   return await callApi(`/api/auth/cronofy/calendar?counselor_id=${id}`);
};

export const getCronofyElementToken = async (sub: string) => {
   return await callApi(`/api/auth/cronofy/element_tokens/${sub}`);
};

// noinspection JSUnusedAssignment
export const authenticateCronofy = async (accessToken: string) => {
   let elementToken;

   const response = await getCronofySub(accessToken);

   if (response.data.error) {
      // noinspection JSUnusedAssignment
      throw response.data.error;
   }

   if (!response.data.sub || !response.data['cronofy.data']) {
      throw new Error('Unexpected response payload');
   }

   const tokenResponse = await getCronofyElementToken(response.data.sub);
   if (tokenResponse.data.element_token) {
      elementToken = tokenResponse.data.element_token.token;
   }

   return {
      ...response.data,
      elementToken,
   };
};

export const removeConnectedCalendarService = async (accessToken: string, providerId: any) => {
   return await callApi(`/api/providers/${providerId}`, accessToken, {
      method: 'DELETE',
   });
};
