import React from 'react';
import { CurrentTime } from '../';
import { AccountOption } from '../';
import { isMobile } from 'react-device-detect';
import Button from 'devextreme-react/button';
import './Header.scss';

interface HeaderProps {
   toggleMenu: any;
}

const Header: React.FC<HeaderProps> = props => {
   const { toggleMenu } = props;

   return (
      <div className="header d-flex align-items-center">
         {isMobile && (
            <Button
               onClick={toggleMenu}
               elementAttr={{ class: 'toggle-button', 'data-testid': 'toggle-button' }}
               icon="menu"
            />
         )}
         {!isMobile && <CurrentTime />}
         <AccountOption style={{ marginLeft: 'auto' }} />
      </div>
   );
};

export default Header;
