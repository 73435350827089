import React, { useState, useEffect } from 'react';
import { LinkCard, NewLinkModal } from '../';
import { Popup, Position } from 'devextreme-react/popup';
import { isMobile } from 'react-device-detect';
import { Button } from 'devextreme-react/button';
import SpinnerIcon from '../../assets/icons/icon-spinner.gif';
import { listLinks, deleteLink } from '../../services/linkService';

import './Links.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { AppConfig } from '../../config';

const Links: React.FC = props => {
   const [isShowCreationModal, setShowCreationModal] = useState(false);
   const [isShowConfirmPopup, setShowConfirmPopup] = useState(false);
   const [isShowDeletePopup, setShowDeletePopup] = useState(false);
   const [isShowDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
   const [newLink, setNewLink] = useState('A New Appointment Link');
   const [deletedLink, setDeletedLink] = useState('An Appointment Link');
   const [isShowSpinner, setShowSpinner] = useState(true);
   const [links, setLinks] = useState([]);
   const [deleteLinkIndex, setDeleteLinkIndex] = useState(-1);

   const { getAccessTokenSilently } = useAuth0();

   useEffect(() => {
      (async () => {
         const accessToken = await getAccessTokenSilently({
            audience: AppConfig.apiUrl,
         });
         const response = await listLinks(accessToken);
         if (200 === response.status) {
            updateLinks(response.data);
         } else {
            console.log('Unable to fetch links');
         }
      })();
   }, [isShowCreationModal, isShowDeleteConfirmPopup]);

   const updateLinks = (allLinks: any) => {
      if (allLinks && allLinks.length >= 0) {
         setLinks(allLinks);
      }
      setShowSpinner(false);
   };

   const showCreateLinkModal = () => {
      setShowCreationModal(true);
   };

   const hideCreateLinkModal = () => {
      setShowCreationModal(false);
   };

   const onLinkCreated = (submission: any) => {
      setNewLink(submission.data.title);
      setShowConfirmPopup(true);
      hideCreateLinkModal();
   };

   const onDelete = (index: any) => {
      setDeleteLinkIndex(index);
      setShowDeletePopup(true);
   };

   return (
      <div className="links">
         {isShowSpinner ? (
            <img alt="spinner" width="50px" src={SpinnerIcon} className="spinner" />
         ) : (
            <div className="contents">
               {links.length === 0 ? (
                  <div className={'contents-container content-block responsive-paddings'} id="container">
                     <div className="empty-label">
                        <p>There are currently no appointment link.</p>
                        <Button
                           className={'create-button'}
                           text="Create a new one"
                           onClick={showCreateLinkModal}
                           elementAttr={{ 'data-testid': 'create-button' }}
                        />
                     </div>
                  </div>
               ) : (
                  <div>
                     <div className="row">
                        {links.map((link: any, index: any) => (
                           <LinkCard
                              _id={link._id}
                              id={link.data.id}
                              title={link.data.title}
                              description={link.data.description}
                              isRequiredID={link.data.requireStudentId}
                              key={link.id}
                              hasQuestionnaire={link.data.hasQuestionnaire}
                              onDelete={() => {
                                 onDelete(index);
                                 setDeletedLink(link.data.title);
                              }}
                           />
                        ))}
                     </div>
                     <Button
                        className={'create-button primary-button'}
                        text="Create a new one"
                        onClick={showCreateLinkModal}
                        elementAttr={{ 'data-testid': 'create-button' }}
                     />
                  </div>
               )}
            </div>
         )}
         <NewLinkModal
            visible={isShowCreationModal}
            hideModal={hideCreateLinkModal}
            elementAttr={{ 'data-testid': 'modal' }}
            onSubmitDone={onLinkCreated}
         />
         <Popup
            className="popup-creation-confirm"
            visible={isShowConfirmPopup}
            onHiding={() => {
               setShowConfirmPopup(false);
            }}
            closeOnOutsideClick={true}
            shading={isMobile}
            width={isMobile ? 'calc(100% - 40px)' : '80%'}
            height="auto"
            title="New Link Created!"
            deferRendering={false}
         >
            <Position of={isMobile ? '#content' : '#container'} />
            <p>"{newLink}" has been created.</p>
            <div className="d-flex flex-row-reverse">
               <Button
                  text="OK"
                  onClick={() => {
                     setShowConfirmPopup(false);
                  }}
                  className="hide-button primary-button"
               />
            </div>
         </Popup>
         <Popup
            className="popup-creation-confirm"
            visible={isShowDeletePopup}
            onHiding={() => {
               setShowDeletePopup(false);
            }}
            closeOnOutsideClick={true}
            shading={isMobile}
            width={isMobile ? 'calc(100% - 40px)' : '80%'}
            height="auto"
            title="Delete"
            deferRendering={false}
         >
            <Position of={isMobile ? '#content' : '#container'} />
            <p>Are you sure you want to delete "{deletedLink}" appointment link?</p>
            <div className="d-flex flex-row-reverse">
               <Button
                  text="Yes"
                  onClick={async () => {
                     const accessToken = await getAccessTokenSilently({
                        audience: AppConfig.apiUrl,
                     });
                     const id = links[deleteLinkIndex]['_id'];
                     await deleteLink(accessToken, id);
                     const response = await listLinks(accessToken);

                     if (200 === response.status) {
                        updateLinks(response.data);
                     } else {
                        console.log('Unable to fetch links');
                     }

                     setShowDeleteConfirmPopup(true);
                     setShowDeletePopup(false);
                  }}
                  className="hide-button primary-button"
               />
               <Button
                  text="No"
                  onClick={() => {
                     setShowDeletePopup(false);
                  }}
                  className="second-button"
               />
            </div>
         </Popup>
         <Popup
            className="popup-delete-confirm"
            visible={isShowDeleteConfirmPopup}
            onHiding={() => {
               setShowDeleteConfirmPopup(false);
            }}
            closeOnOutsideClick={true}
            shading={isMobile}
            width={isMobile ? 'calc(100% - 40px)' : '80%'}
            height="auto"
            title="Appointment Link Deleted"
            deferRendering={false}
         >
            <Position of={isMobile ? '#content' : '#container'} />
            <p>"{deletedLink}" has successfully been deleted.</p>
            <div className="d-flex flex-row-reverse">
               <Button
                  text="OK"
                  onClick={() => {
                     setShowDeleteConfirmPopup(false);
                  }}
                  className="hide-button primary-button"
               />
            </div>
         </Popup>
      </div>
   );
};

export default Links;
