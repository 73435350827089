import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import LeftBgImg from '../../assets/images/auth_left.png';
import RightBgImg from '../../assets/images/auth_right.png';
import SpinnerIcon from '../../assets/icons/icon-spinner.gif';

import './GetStarted.scss';
import { getLinkDetail } from '../../services/linkService';

export default function GetStarted() {
   const history = useHistory();
   const [counselorName, setCounselorName] = useState('');

   const { linkId } = useParams<Record<string, string | undefined>>();

   useEffect(() => {
      (async () => {
         const response = await getLinkDetail(linkId);
         console.log('link details response', response);
         setCounselorName(response.data.counselor.firstName);
      })();
   }, [history.location.search]);

   return (
      <div className="container getstarted" style={{ height: '100vh', textAlign: 'center' }}>
         <img alt="left-bg" style={{ zIndex: -1 }} src={LeftBgImg} className="left-bg" />
         <img alt="right-bg" style={{ zIndex: -1 }} src={RightBgImg} className="right-bg" />

         <h2>You made it!</h2>

         {counselorName.length === 0 ? (
            <img alt="spinner" width="50px" src={SpinnerIcon} className="spinner" data-testid="spinner" />
         ) : (
            <p>
               We know, clicking on a link is hard work but we need a little more info from you. <br />
               Let's get you in to meet with {counselorName}!
            </p>
         )}

         <Link style={{ marginTop: '5%' }} to={`/student-form/${linkId}`}>
            Get Started
         </Link>
      </div>
   );
}
