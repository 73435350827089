import React, { useState, useEffect } from 'react';
import { Form } from 'react-formio';
import { useHistory, useParams } from 'react-router-dom';
import LeftBgImg from '../../assets/images/auth_left.png';
import RightBgImg from '../../assets/images/auth_right.png';
import { getLinkDetail } from '../../services/linkService';

import './StudentForm.scss';

export default function StudentForm() {
   const history = useHistory();
   const formioBaseURL = window._ENV.FormIOProjectURL;
   const [isRequiredId, setRequiredId] = useState(false);

   // TODO: see if we really need it
   const studentMainInfo = window.localStorage.getItem('student_main_info');

   const { linkId } = useParams<Record<string, string | undefined>>();

   const onSubmitDone = (submission: any) => {
      window.localStorage.setItem('student_id', submission._id);
      window.localStorage.setItem('student_first_name', submission.data.firstName);
      console.log(submission.data);
      window.localStorage.setItem('student_main_info', JSON.stringify(submission.data));
      history.push(`/questions/${linkId}`);
   };

   useEffect(() => {
      (async () => {
         const response = await getLinkDetail(linkId);

         if(response && response.data && response.data.link && response.data.link.requireStudentId === 'yes') {
            setRequiredId(true);            
         }
         
      })();
   }, [history.location.search]);

   return (
      <div className="container studentForm">
         <img alt="left-bg" style={{ zIndex: -1 }} src={LeftBgImg} className="left-bg" />
         <img alt="right-bg" style={{ zIndex: -1 }} src={RightBgImg} className="right-bg" />

         {isRequiredId ? (
            <Form
               onSubmitDone={onSubmitDone}
               src={`${formioBaseURL}studentinformationformRequiredId`}
               submission={{ data: studentMainInfo ? JSON.parse(studentMainInfo || '') : {} }}
            />
         ) : (
            <Form
               onSubmitDone={onSubmitDone}
               src={`${formioBaseURL}studentinformationform`}
               submission={{ data: studentMainInfo ? JSON.parse(studentMainInfo || '') : {} }}
            />
         )}
      </div>
   );
}
