import React, { useState, useCallback } from 'react';
import Drawer from 'devextreme-react/drawer';
import Button from 'devextreme-react/button';
import Toolbar, { Item } from 'devextreme-react/toolbar';

import { Template } from 'devextreme-react/core/template';
import { isMobile } from 'react-device-detect';
import { SideBar } from '../../components';
import { Header } from '../../components';

import './DashboardLayout.scss';

interface DashboardProps {
   children?: any;
}

const Dashboard: React.FC<DashboardProps> = props => {
   const { children } = props;
   const [opened, setOpened] = useState(true);
   const onOutsideClick = (e: any) => {
      if (isMobile) setOpened(false);
      return true;
   };

   const toggleMenu = useCallback(({ event }) => {
      setOpened(prevOpened => (prevOpened === false ? true : false));
      event.stopPropagation();
   }, []);

   return (
      <Drawer
         className="sidebar-container drawer"
         elementAttr={{ 'data-testid': 'drawer' }}
         opened={opened}
         openedStateMode={isMobile ? 'overlap' : 'shrink'}
         position={'left'}
         revealMode={isMobile ? 'slide' : 'expand'}
         minSize={isMobile ? 0 : 86}
         maxSize={280}
         shading={isMobile ? true : false}
         closeOnOutsideClick={onOutsideClick}
         template={'menu'}
      >
         <Header toggleMenu={toggleMenu} />
         <div id="content" data-testid="content">
            {children}
         </div>

         <Template name={'menu'}>
            <Toolbar id="navigation-header">
               <Item location={'before'}>
                  <Button
                     elementAttr={{ class: 'toggle-button', 'data-testid': 'toggle-button' }}
                     icon="menu"
                     onClick={toggleMenu}
                  />
               </Item>
            </Toolbar>
            <SideBar opened={opened} toggleTrigger={setOpened} />
         </Template>
      </Drawer>
   );
};

export default Dashboard;
