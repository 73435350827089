import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { isMobile } from 'react-device-detect';
import { navigation } from '../../app-navigation';
import { NavLink, useLocation } from 'react-router-dom';

import './SideBar.scss';

interface SideBarProps {
   opened: boolean;
   toggleTrigger: any;
}

const SideBar: React.FC<SideBarProps> = props => {
   const { opened, toggleTrigger } = props;
   const location = useLocation();
   const items = useMemo(() => navigation.map(item => ({ ...item, expanded: true })), []);
   const treeviewRef: any = useRef(null);

   useEffect(() => {
      if (treeviewRef && treeviewRef.current && treeviewRef.current.instance) {
         if (opened) {
            //When we open the drawer
            treeviewRef.current.instance.expandAll();
            treeviewRef.current.instance.unselectAll();
         } else {
            //When we close the drawer
            treeviewRef.current.instance.collapseAll();
            const nodes = treeviewRef.current.instance.getNodes();

            nodes.forEach((node: any) => {
               if (node.items.filter((item: any) => item.itemData.path === location.pathname).length > 0) {
                  treeviewRef.current.instance.selectItem(node.key);
               }
            });
         }
      }
   }, [opened, location]);

   const onNavigationChanged = useCallback(
      ({ itemData: { path }, event, node }) => {
         if (isMobile && node.children.length === 0) {
            toggleTrigger(false);
         }
         if (node.expanded === true) {
            toggleTrigger(true);
         }

         if (node.children.length > 0) {
            //About expanded Nodes
            if (node.expanded === false) {
               //When we close it
               if (node.items.filter((item: any) => item.itemData.path === location.pathname).length > 0) {
                  treeviewRef.current.instance.selectItem(node.key);
               }
            } else {
               //When we open it
               treeviewRef.current.instance.unselectAll();
            }
         } else {
            treeviewRef.current.instance.unselectAll();
         }
      },
      [location, toggleTrigger],
   );

   const renderItem = (item: any) => {
      return item.path ? (
         <NavLink className="item" activeClassName="node-focused" to={item.path}>
            {item.icon && <img alt="icon" src={require('../../assets/icons/' + item.icon)} />}
            {item.text}
         </NavLink>
      ) : (
         <li className="item">
            {item.icon && <img alt="icon" src={require('../../assets/icons/' + item.icon)} />}
            {item.text}
         </li>
      );
   };
   return (
      <div className="side-bar" style={{ width: 280 }}>
         <TreeView
            items={items}
            itemRender={renderItem}
            selectionMode={'single'}
            focusStateEnabled={false}
            expandEvent={'click'}
            onItemClick={onNavigationChanged}
            width={'100%'}
            ref={treeviewRef}
         />
      </div>
   );
};

export default SideBar;
