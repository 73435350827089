import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Links, Calendars } from '../../components';

import './Settings.scss';

const Settings = () => {
   return (
      <div className={'settings-page'}>
         <h1 className={'content-block'}> Settings</h1>
         <div className={'full-height content-block card responsive-padings'}>
            <div className={'button-container'}>
               <NavLink className="item" activeClassName="focused" to={'/settings/links'}>
                  Links
               </NavLink>
               <NavLink className="item" activeClassName="focused" to={'/settings/calendar'}>
                  Calendar
               </NavLink>
            </div>
            <Switch>
               <Route path="/settings/links" component={Links} />
               <Route path="/settings/calendar" component={Calendars} />
            </Switch>
         </div>
      </div>
   );
};

export default Settings;
