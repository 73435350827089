import { callApi } from '../hooks/use-api';

export const getAvailabilityRuleID = (counselorId: string) => `availability_rule_${counselorId}`;

export const getSlots = async (sub: any, counselorId: any, start: any, end: any) => {
   return callApi(`/api/availability?counselor_id=${counselorId}`, undefined, {
      method: 'POST',
      data: {
         response_format: 'slots',
         participants: [
            {
               members: [
                  {
                     sub: sub,
                     managed_availability: true,
                     availability_rule_ids: [getAvailabilityRuleID(counselorId)],
                  },
               ],
               required: 'all',
            },
         ],
         required_duration: { minutes: 60 },
         query_periods: [
            {
               start: start,
               end: end,
            },
         ],
      },
   });
};

export const saveEvent = async (
   counselorId: any,
   start: any,
   end: any,
   calendarId: any,
   meetingId: any,
   studentId: any,
   comment: any,
) => {
   return callApi(`/api/event?counselor_id=${counselorId}`, undefined, {
      method: 'POST',
      data: {
         counselor_id: counselorId,
         calendar_id: calendarId,
         meeting_id: meetingId,
         student_id: studentId,
         comment,
         start,
         end,
      },
   });
};
