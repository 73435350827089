import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

export interface ApiResponse {
   error: string | null;
   loading: boolean;
   data: any;
   refresh: any;
}

/**
 * A React Hook that is used to retrieve data from the API.
 *
 * @param url Absolute/Relative URL.
 * @param options Additional parameters for the underlying callApi call.
 */
export const useApi = (url: string, options: any = {}): ApiResponse => {
   const { getAccessTokenSilently } = useAuth0();

   const [state, setState] = useState({
      error: null,
      loading: true,
      data: null,
   });
   const [refreshIndex, setRefreshIndex] = useState(0);

   useEffect(() => {
      (async () => {
         try {
            const { audience, scope, ...requestOptions } = options;
            const accessToken = await getAccessTokenSilently({ audience, scope });

            const response = await callApi(url, accessToken, requestOptions);
            const data = await response.json();

            // noinspection ExceptionCaughtLocallyJS
            // Non-2xx responses are treated as errors
            if (!response.status.toString().startsWith('2')) {
               throw data['error'];
            }

            setState({
               ...state,
               data,
               error: null,
               loading: false,
            });
         } catch (error) {
            setState({
               ...state,
               error,
               loading: false,
            });
         }
      })();
   }, [refreshIndex]);

   return {
      ...state,
      refresh: () => setRefreshIndex(refreshIndex + 1),
   };
};

/**
 * Sends an authorized HTTP request to a given URL.
 * @param url Absolute/relative URL.
 * @param accessToken The access token or undefined for public endpoints.
 * @param options Additional parameters for the underlying axios request call.
 * @returns Response object.
 */
export const callApi = async (url: string, accessToken?: string, options: any = {}): Promise<any> => {
   const requestConfig = {
      url,
      ...options,
      headers: {
         ...options.headers,
      },
   };

   if (!!accessToken) {
      requestConfig.headers = {
         ...requestConfig.headers,
         Authorization: `Bearer ${accessToken}`,
      };
   }

   let response;
   try {
      response = await axios.request(requestConfig);
   } catch (e) {
      response = e.response;
   }

   return response;
};
