import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './StudentCard.scss';

interface StudentCardProps {
   _id: string;
   id: string;
   name: string;
   email: string;
   phone: string;
}

const StudentCard: React.FC<StudentCardProps> = props => {
   const { _id, id, phone, name, email } = props;

   return (
      <div className="student-card-container col-sm-12 col-md-12 col-lg-6 col-xl-4">
         <div className="card student-card-content">
            <Link to={`/students/${_id}`}>
               <h2>{name}</h2>
            </Link>
            <p>Student Id: {id}</p>
            <Row>
               <Col lg={12} xl={5}>
                  <p>Phone: {phone}</p>
               </Col>
               <Col lg={12} xl={7}>
                  <p>E-mail: {email}</p>
               </Col>
            </Row>
         </div>
      </div>
   );
};

export default StudentCard;
