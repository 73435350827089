import React from 'react';
import { Form } from 'react-formio';
import { useHistory, useParams } from 'react-router-dom';

import LeftBgImg from '../../assets/images/auth_left.png';
import RightBgImg from '../../assets/images/auth_right.png';
import { saveQuiz } from '../../services/quizService';

import './QuestionTemplate.scss';
import { getLinkDetail } from '../../services/linkService';

export default function QuestionTemplate() {
   const history = useHistory();
   const formioBaseURL = window._ENV.FormIOProjectURL;

   const { linkId } = useParams<Record<string, string | undefined>>();

   const onSubmitDone = async (submission: any) => {
      const studentId = window.localStorage.getItem('student_id');

      const linkDetailResponse = await getLinkDetail(linkId);

      await saveQuiz(submission._id, studentId, linkDetailResponse.data.counselor.id, 'questionnaireTemplateOne');

      history.push(`/slot-picker/${linkId}`);
   };

   const goBack = () => {
      history.push(`/student-form/${linkId}`);
   };

   return (
      <div className="container questionForm">
         <img alt="left-bg" style={{ zIndex: -1 }} src={LeftBgImg} className="left-bg" />
         <img alt="right-bg" style={{ zIndex: -1 }} src={RightBgImg} className="right-bg" />

         <Form
            onCustomEvent={goBack}
            onSubmitDone={onSubmitDone}
            src={`${formioBaseURL}questionnairetemplateone`}
         />
      </div>
   );
}
