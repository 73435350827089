import { callApi } from '../hooks/use-api';

export const listLinks = async (accessToken: string) => {
   return await callApi('/api/links', accessToken);
};

export const getLinkDetail = async (linkId: any) => {
   return await callApi(`/api/links/${linkId}`, undefined);
};

export const deleteLink = async (accessToken: string, id: any) => {
   return await callApi(`/api/links/${id}`, accessToken, {
      method: 'DELETE',
   });
};
