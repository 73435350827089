import React, { useEffect, useState } from 'react';
import { StudentCard } from '../../components';
import { getStudents } from '../../services/studentService';
import SpinnerIcon from '../../assets/icons/icon-spinner.gif';
import { AppConfig } from '../../config';
import { useAuth0 } from '@auth0/auth0-react';

export default function Students() {
   const { getAccessTokenSilently } = useAuth0();
   const [students, setStudents] = useState([]);
   const [isShowSpinner, setShowSpinner] = useState(true);

   useEffect(() => {
      (async () => {
         const accessToken = await getAccessTokenSilently({
            audience: AppConfig.apiUrl,
         });

         getStudents(accessToken, onGetSuccess, onGetError);
      })();
   }, []); // Empty array only calls function on first load.

   const onGetSuccess = (response: any) => {
      const allStudents = response.data;

      if (allStudents.length > 0) {
         const sortedArray = allStudents.sort((a: any, b: any) => {
            return a.data.lastName + a.data.firstName > b.data.lastName + b.data.firstName ? 1 : -1;
         });

         setStudents(sortedArray);
      }
      setShowSpinner(false);
   };

   const onGetError = (response: any) => {
      console.log('Students Fetch Error');
   };

   return (
      <div style={{ width: '100%' }}>
         <h1>Student Profiles</h1>
         {isShowSpinner ? (
            <img alt="spinner" width="50px" src={SpinnerIcon} className="spinner" data-testid="spinner" />
         ) : (
            <div className="students row" data-testid="students">
               {students.map((student: any) => (
                  <StudentCard
                     _id={student._id}
                     id={student.data.studentId}
                     name={student.data.firstName + ' ' + student.data.lastName}
                     email={student.data.email}
                     phone={student.data.phoneNumber}
                     key={student._id}
                  />
               ))}
            </div>
         )}
      </div>
   );
}
