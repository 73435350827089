import { callApi } from '../hooks/use-api';

// TODO: make it consistent with other services by awaiting callApi instead of passing callback function references
export const getStudents = (accessToken: string, onSuccess: any, onError: any) => {
   callApi('/api/students', accessToken).then(onSuccess).catch(onError);
};

// TODO: make it consistent with other services by awaiting callApi instead of passing callback function references
export const getStudent = (accessToken: string, id: any, onSuccess: any, onError: any) => {
   callApi(`/api/students/${id}`, accessToken).then(onSuccess).catch(onError);
};
