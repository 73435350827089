import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { AppConfig } from '../config';

interface Auth0ProviderWithHistoryProps {
   children?: any;
}

const Auth0ProviderWithHistory = (props: Auth0ProviderWithHistoryProps) => {
   const domain = AppConfig.auth0Domain;
   const clientId = AppConfig.auth0ClientId;

   const { children } = props;

   const history = useHistory();

   const onRedirectCallback = (appState: any) => {
      history.push(appState?.returnTo || window.location.pathname);
   };

   return (
      <Auth0Provider
         domain={domain}
         clientId={clientId}
         redirectUri={window.location.origin}
         onRedirectCallback={onRedirectCallback}
         audience={AppConfig.apiUrl}
      >
         {children}
      </Auth0Provider>
   );
};

export default Auth0ProviderWithHistory;
