export const navigation = [
   // Temporarily removed until Dashboard is more complete
   // {
   //    text: 'Dashboard',
   //    path: '/home',
   //    icon: 'icon-dashboard.png',
   // },
   {
      text: 'Calendar',
      path: '/calendar',
      icon: 'icon-calendar.png',
   },
   {
      text: 'Student Profiles',
      path: '/students',
      icon: 'icon-studentprofile.png',
   },
   {
      text: 'Settings',
      icon: 'icon-settings.png',
      items: [
         {
            text: 'Links',
            path: '/settings/links',
         },
         {
            text: 'Calendar',
            path: '/settings/calendar',
         },
      ],
   },
];
