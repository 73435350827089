declare global {
   interface Window {
      _ENV: {
         Auth0Domain: string;
         Auth0ClientID: string;
         FormIOProjectURL: string;
         CronofyClientID: string;
      };
   }
}

window._ENV = window._ENV || {
   Auth0Domain: '',
   Auth0ClientID: '',
   FormIOProjectURL: '',
   CronofyClientID: '',
};

export const AppConfig = {
   auth0Domain: window._ENV.Auth0Domain, // this value is loaded from backend via a script tag in index.html
   auth0ClientId: window._ENV.Auth0ClientID, // this value is loaded from backend via a script tag in index.html
   formIOProjectURL: window._ENV.FormIOProjectURL, // this value is loaded from backend via a script tag in index.html
   cronofyClientID: window._ENV.CronofyClientID, // this value is loaded from backend via a script tag in index.html
   // Backend API server in every environment MUST always be mounted under the path /api for the hostname that is serving the application
   apiUrl: window.location.origin + '/api',
};
