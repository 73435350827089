import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import SpinnerIcon from '../../assets/icons/icon-spinner.gif';
import { getStudent } from '../../services/studentService';
import { getQuizDetail, getQuizList } from '../../services/quizService';
import { getDocumentList, saveDocument } from '../../services/documentService';
import { Button } from 'devextreme-react/button';
import './StudentDetail.scss';
import UploadFileModal from '../UploadFileModal/UploadFileModal';
import { AppConfig } from '../../config';
import { useAuth0 } from '@auth0/auth0-react';

const StudentDetail: React.FC = props => {
   const { getAccessTokenSilently } = useAuth0();

   const history = useHistory();
   const pathname = history.location.pathname;
   const _id = pathname.split('/').reverse()[0];

   const [name, setName] = useState('');
   const [phone, setPhone] = useState('');
   const [email, setEmail] = useState('');
   const [id, setId] = useState('');
   const [time, setTime] = useState('');
   const [comment, setComment] = useState('Additional Comments: N/A');
   const [isShowUploadModal, setShowUploadModal] = useState(false);
   const [documents, setDocuments] = useState([]);
   const [questionnaires, setQuestionnaires] = useState([]);

   const getDocumentsList = useCallback(async () => {
      const accessToken = await getAccessTokenSilently({
         audience: AppConfig.apiUrl,
      });

      const documentList = await getDocumentList(accessToken, _id);
      if (documentList.data && documentList.data.length > 0) {
         setDocuments(documentList.data);
      }
   }, [_id]);

   useEffect(() => {
      (async () => {
         const accessToken = await getAccessTokenSilently({
            audience: AppConfig.apiUrl,
         });

         const quizlist = await getQuizList(accessToken, _id);

         if (quizlist.data) {
            Promise.all(quizlist.data.map((item: any) => getQuizDetail(accessToken, item.quiz_id))).then(values => {
               values.forEach((link: any, index: any) => {
                  quizlist.data[index].link = link.data;
               });

               setQuestionnaires(quizlist.data);
            });
         }

         getStudent(accessToken, _id, onGetSuccess, onGetError);
         await getDocumentsList();
      })();
   }, [_id, getDocumentsList]); // Empty array only calls function on first load.

   const onGetSuccess = (response: any) => {
      const studentInfo = response.data;

      if (studentInfo.data) {
         setName(studentInfo.data.firstName + ' ' + studentInfo.data.lastName);
         setEmail(studentInfo.data.email);
         setPhone(studentInfo.data.phoneNumber);
         setId(studentInfo.data.studentId);
      }

      if (studentInfo.appointment) {
         const start = studentInfo.appointment.start;
         const end = studentInfo.appointment.end;
         const commentContent = studentInfo.appointment.comment;
         const day = moment(start).format('dddd, MMMM Do');
         const timeduration = moment(start).format('LT') + ' - ' + moment(end).format('LT');
         setTime(day + ', ' + timeduration);
         if (commentContent) setComment('Additional Comments: ' + commentContent);
      }
   };

   const onGetError = (response: any) => {
      console.log('Student Fetch Error');
   };

   const onUpload = async (submission: any) => {
      const accessToken = await getAccessTokenSilently({
         audience: AppConfig.apiUrl,
      });

      // TODO: Obtain counselor id from Id Token Claims
      const counselorId = window.localStorage.getItem('user_id');
      await saveDocument(accessToken, submission._id, _id, counselorId);

      setShowUploadModal(false);
      await getDocumentsList();
   };

   return (
      <div style={{ width: `100%` }}>
         <h1>Student Profiles {name.length > 0 && ' / ' + name}</h1>
         <Link className="back-to-button" to="/students">
            <i className="dx-icon-arrowleft" />
            Go back to Student Profiles
         </Link>
         {email ? (
            <div className="card student-detail-content">
               <Row className="wrapper-content">
                  <Col>
                     <h2>{name}</h2>
                     <p>Student ID: {id}</p>
                     <p>Phone: {phone}</p>
                     <p>E-mail: {email}</p>
                     <hr />
                     <h3>Upcoming Appointments</h3>
                     {time ? (
                        <p data-testid="time">{time}</p>
                     ) : (
                        <p data-testid="time">There are no upcoming appointments at this time.</p>
                     )}
                     {time && <p data-testid="comment">{comment}</p>}
                     <hr style={{ marginTop: 20 }} />
                     <h3>Questionnaires</h3>
                     {questionnaires.length > 0 ? (
                        <div>
                           {questionnaires.map((quiz: any) => (
                              <a
                                 className="quiz"
                                 style={{ display: 'flex' }}
                                 href={quiz.link}
                                 target="_blank"
                                 rel="noopener noreferrer"
                              >
                                 <p>{moment(quiz.created_at).format('L')}</p>
                                 <p className="mx-1"> - </p>
                                 <p className="templateName" style={{ textTransform: 'capitalize' }}>
                                    {quiz.template}
                                 </p>
                              </a>
                           ))}
                        </div>
                     ) : (
                        <p>There are no questionnaires submitted at this time.</p>
                     )}
                  </Col>
                  <Col md={4} id="document-section">
                     <h3>Documents</h3>
                     {documents.length > 0 ? (
                        <div>
                           {documents.map((item: any) => (
                              <div className="document-item card" key={item.id}>
                                 <a
                                    className="file_url"
                                    href={`${AppConfig.apiUrl}/documents/download/${item.document_id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    {item.file_name}
                                 </a>
                                 <div className="bottom-line">
                                    <p>Description: {item.description ? item.description : 'N/A'}</p>
                                    <p>{moment(item.created_at).format('L')}</p>
                                 </div>
                              </div>
                           ))}
                        </div>
                     ) : (
                        <p>There are no documents at this time.</p>
                     )}

                     <Button
                        className={'primary-button'}
                        text="Upload a Document"
                        onClick={() => setShowUploadModal(true)}
                        elementAttr={{ 'data-testid': 'create-button' }}
                     />
                     <UploadFileModal
                        visible={isShowUploadModal}
                        hideModal={() => setShowUploadModal(false)}
                        elementAttr={{ 'data-testid': 'modal' }}
                        onSubmitDone={onUpload}
                     />
                  </Col>
               </Row>
            </div>
         ) : (
            <img alt="spinner" width="50px" src={SpinnerIcon} className="spinner" data-testid="spinner" />
         )}
      </div>
   );
};

export default StudentDetail;
