import { callApi } from '../hooks/use-api';

export const saveQuiz = async (quizId: any, studentId: any, counselorId: any, template: any) => {
   return await callApi(`/api/quiz`, undefined, {
      method: 'POST',
      data: {
         quiz_id: quizId,
         student_id: studentId,
         counselor_id: counselorId,
         template,
      },
   });
};

export const getQuizDetail = async (accessToken: string, quizId: string) => {
   return await callApi(`/api/quiz/download/${quizId}`, accessToken);
};

export const getQuizList = async (accessToken: string, studentId: string) => {
   return await callApi(`/api/quiz/${studentId}`, accessToken);
};
