import React, { useCallback, useEffect, useState } from 'react';
import { Popup, Position } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { isMobile } from 'react-device-detect';
import { authenticateCronofy, getCronofyProvider } from '../../services/cronofyService';
import { getAvailabilityRuleID } from '../../services/availabilityService';
import { removeConnectedCalendarService } from '../../services/cronofyService';
import { AppConfig } from '../../config';
import './Calendars.scss';
import { useAuth0 } from '@auth0/auth0-react';

declare global {
   interface Window {
      CronofyElements: any;
   }
}

const Calendars: React.FC = props => {
   const [calendarConnected, setCalendarConnected] = useState(false);
   const [isShowDeletePopup, setShowDeletePopup] = useState(false);
   const [calendarServiceProvider, setCalendarServiceProvider] = useState('');
   const [calendarServiceEmail, setCalendarServiceEmail] = useState('');

   const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

   const onLoad = useCallback(async () => {
      const accessToken = await getAccessTokenSilently({
         audience: AppConfig.apiUrl,
      });

      const idTokenClaims = await getIdTokenClaims({
         audience: AppConfig.apiUrl,
      });
      const counselorId = idTokenClaims['https://login.eldertree.io/form-io-counselor-id'];

      let elementToken;
      try {
         const response = await authenticateCronofy(accessToken);
         elementToken = response.elementToken;
      } catch (e) {
         console.log(e);
         return;
      }

      await connectCalendar(accessToken);

      if (window.CronofyElements && elementToken) {
         window.CronofyElements.AvailabilityRules({
            element_token: elementToken,
            target_id: 'cronofy-availability-rules',
            availability_rule_id: getAvailabilityRuleID(counselorId),
            config: {
               start_time: '08:00',
               end_time: '18:00',
               duration: 60,
            },
            styles: {
               colors: {
                  available: '#CFE3F7',
                  unavailable: 'white',
               },
               prefix: 'cronofy-calendar-availability',
            },
            tzid: 'America/Los_Angeles',
         });
      }
   }, [getAccessTokenSilently]);

   const connectCalendar = async (accessToken: string) => {
      const provider = await getCronofyProvider(accessToken);

      if (!provider.data.id || !provider.data.email || !provider.data.provider) {
         setCalendarConnected(false);
         return;
      }

      window.localStorage.setItem('calendar_provider_id', provider.data.id);
      setCalendarServiceEmail(provider.data.email);
      setCalendarServiceProvider(provider.data.provider);
      setCalendarConnected(true);
   };

   const removeCalendar = async () => {
      const accessToken = await getAccessTokenSilently({
         audience: AppConfig.apiUrl,
      });
      const providerId = window.localStorage.getItem('calendar_provider_id');
      await removeConnectedCalendarService(accessToken, providerId);

      window.localStorage.removeItem('calendar_provider_id');
      window.localStorage.removeItem('cronofy_element_token');
      window.localStorage.removeItem('cronofy_sub');
      window.localStorage.removeItem('calendar_id');

      setCalendarConnected(false);
   };

   // TODO: getting user_id from localStorage is fragile, do something about it
   const counselorId = window.localStorage.getItem('user_id') || '';
   const redirectURI = AppConfig.apiUrl + '/auth/cronofy/callback';

   const connectCalendarURL = new URL('https://app.cronofy.com/oauth/v2/authorize');
   connectCalendarURL.searchParams.append('client_id', AppConfig.cronofyClientID);
   connectCalendarURL.searchParams.append('redirect_uri', redirectURI);
   connectCalendarURL.searchParams.append('response_type', 'code');
   connectCalendarURL.searchParams.append('scope', 'read_write');
   connectCalendarURL.searchParams.append('state', counselorId);
   connectCalendarURL.searchParams.append('avoid_linking', 'true');

   useEffect(() => {
      onLoad();
   }, [onLoad]);

   return (
      <div className="calendars-page">
         <h2>Link Calendar Service</h2>
         {!calendarConnected && (
            <div>
               <p>Connect a calendar service to your account.</p>
               <div className="connector-wrapper">
                  <a href={connectCalendarURL.href} className="connect-button">
                     Add calendar service
                  </a>
               </div>
            </div>
         )}
         {calendarConnected && (
            <div>
               <p>This is the calendar service you have connected to your account.</p>
               <div className="status-bar">
                  <div className="item">
                     <span className="provider">{calendarServiceProvider}</span>
                     <span className="email">{calendarServiceEmail}</span>
                  </div>
                  <div className="item">
                     <span className="status">Status: Connected</span>
                     <Button icon="close" className="remove-button" onClick={() => setShowDeletePopup(true)}></Button>
                  </div>
               </div>
            </div>
         )}
         {calendarConnected && (
            <div className="availability">
               <h2 className="mt-4">Availability</h2>
               <p>When are you available during the week? Click and/or drag to edit your availability.</p>
               <div id="cronofy-availability-rules"></div>
            </div>
         )}
         <Popup
            className="popup-creation-confirm"
            visible={isShowDeletePopup}
            onHiding={() => {
               setShowDeletePopup(false);
            }}
            closeOnOutsideClick={true}
            shading={isMobile}
            width={isMobile ? 'calc(100% - 40px)' : '80%'}
            height="auto"
            title="Remove Calendar Service"
            deferRendering={false}
         >
            <Position of={isMobile ? '#content' : '#container'} />
            <p>
               Are you sure you want to remove the calendar service: <br />{' '}
               <span style={{ textTransform: 'capitalize' }}>{calendarServiceProvider}</span> - {calendarServiceEmail}?
            </p>
            <div className="d-flex flex-row-reverse">
               <Button
                  text="Yes"
                  onClick={() => {
                     removeCalendar();
                     setShowDeletePopup(false);
                  }}
                  className="hide-button primary-button"
               />
               <Button
                  text="No"
                  onClick={() => {
                     setShowDeletePopup(false);
                  }}
                  className="second-button"
               />
            </div>
         </Popup>
      </div>
   );
};

export default Calendars;
