import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import './declare_modules.d.ts';
import App from './App';
import store, { history } from './store';
import * as serviceWorker from './serviceWorker';
import { Formio } from 'react-formio';
import { ConnectedRouter } from 'connected-react-router';
import { AppConfig } from './config';

Formio.setProjectUrl(AppConfig.formIOProjectURL);
Formio.setBaseUrl('https://api.form.io');

// TODO: remove it as we get Form.io token from Auth0
// store.dispatch(initAuth());

ReactDOM.render(
   <React.StrictMode>
      <Provider store={store}>
         <ConnectedRouter history={history}>
            <App />
         </ConnectedRouter>
      </Provider>
   </React.StrictMode>,
   document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
