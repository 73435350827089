import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { selectRoot } from 'react-formio';
import { connect } from 'react-redux';
import { Home, Calendar, Students, Settings, GetStarted, StudentForm } from './pages';
import { AppointmentPicker, StudentDetail, QuestionTemplate } from './components';
import { DashboardLayout } from './layouts';
import { AppointmentCancelConfirm } from './components';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const LoggedInRouter = () => {
   return (
      <DashboardLayout>
         <Switch>
            <Route exact path="/" render={() => <Redirect to="/calendar" />} />
            <Route path={`/settings`} component={Settings} />
            <Route path={`/home`} component={Home} />
            <Route path={`/calendar`} component={Calendar} />
            <Route path={`/students/:id`} component={StudentDetail} />
            <Route path={`/students`} component={Students} />
         </Switch>
      </DashboardLayout>
   );
};

const ProtectedRoute = ({ component, ...args }: any) => (
   <Route component={withAuthenticationRequired(component)} {...args} />
);

const AppRouter: React.FC<any> = () => {
   return (
      <Switch>
         <Route path="/get-started/:linkId" component={GetStarted} />
         <Route path="/student-form/:linkId" component={StudentForm} />
         <Route path="/questions/:linkId" component={QuestionTemplate} />
         <Route path="/slot-picker/:linkId" component={AppointmentPicker} />
         <Route path="/cancel-appointment" component={AppointmentCancelConfirm} />
         <ProtectedRoute path="/" component={LoggedInRouter} />
      </Switch>
   );
};

// TODO: document why it is needed
const mapStateToProps = (state: any) => {
   return {
      auth: selectRoot('auth', state),
   };
};

export default connect(mapStateToProps, null)(AppRouter);
