import { callApi } from '../hooks/use-api';

export const saveDocument = async (accessToken: string, documentId: any, studentId: any, counselorId: any) => {
   return await callApi('/api/documents', accessToken, {
      method: 'POST',
      data: {
         document_id: documentId,
         student_id: studentId,
         counselor_id: counselorId,
      },
   });
};

export const getDocumentList = async (accessToken: string, studentId: any) => {
   return await callApi(`/api/documents/${studentId}`, accessToken);
};
