import React from 'react';
import AppRouter from './AppRouter';
import Auth0ProviderWithHistory from './auth0/auth0-provider-with-history';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './App.scss';

const App: React.FC = () => {
   return (
      <div className="App">
         <Auth0ProviderWithHistory>
            <AppRouter />
         </Auth0ProviderWithHistory>
      </div>
   );
};

export default App;
