import React from 'react';
import { Popup, Position } from 'devextreme-react/popup';
import { isMobile } from 'react-device-detect';
import { Form } from 'react-formio';
import './NewLinkModal.scss';

interface NewLinkModalProps {
   visible: boolean;
   hideModal: any;
   elementAttr: any;
   onSubmitDone: (_submission: any) => void;
}

const NewLinkModal: React.FC<NewLinkModalProps> = props => {
   const { visible, hideModal, elementAttr, onSubmitDone } = props;
   const formioBaseURL = window._ENV.FormIOProjectURL;

   return (
      <Popup
         className="popup-meeting-creation"
         visible={visible}
         onHiding={hideModal}
         closeOnOutsideClick={true}
         shading={isMobile}
         width={isMobile ? 'calc(100% - 40px)' : '80%'}
         height="auto"
         title="New Link"
         elementAttr={elementAttr}
         deferRendering={false}
      >
         <Position of={isMobile ? '#content' : '#container'} />
         <div className="form-container" data-testid={'formio-form'}>
            {visible && (
               <Form
                  id="meeting-creation-form"
                  src={`${formioBaseURL}appointmentlink`}
                  onSubmitDone={onSubmitDone}
                  onCustomEvent={hideModal}
               />
            )}
         </div>
      </Popup>
   );
};

export default NewLinkModal;
